import React from 'react';
import HeaderComponent from "../../components/header/header.component";
import Image2Paragraphs from "../../components/image-with-paragraphs/image-with-paragraphs.component";
import CTAComponent from "../../components/call-to-action/cta-footer.component";
import CANCERS_WE_TREAT_DATA from "./cancers-we-treat.data";

import ctaImage from '../../img/Cancer-Care-Images/CTA_Updated.png'
import heroImage from '../../img/Cancer-Care-Images/cancer-we-treat-hero.png';
import ButtonsDirectoryComponent from "../../components/buttons-directory/buttons-directory.component";
import {Helmet} from "react-helmet";
import {hideMask} from "../../components/navigation/navigation.component";

class CancersWeTreatPage extends React.Component {
    pageTitle = "Cancers we treat";
    state = CANCERS_WE_TREAT_DATA;
    render() {
        return (
            <div>
                <Helmet>
                    <title>{this.pageTitle} | Optum Care Cancer Care</title>
                </Helmet>
                <main className='page-body' onClick={hideMask}>
                    <HeaderComponent imageUrl={heroImage} title={this.pageTitle} />
                    <div className='row justify-content-around'>
                        <div className="col-md-8 col-sm-12">
                            <p className="text-centered">
                            We specialize in treating most cancers and hematological disease. This page includes the most commonly treated cancers we see on a daily basis but not every condition. If you have been diagnosed with a cancer or hematological disease that is not on this list please reach out to us so you can discuss your condition further.
                            </p>
                        </div>
                    </div>
                    <ButtonsDirectoryComponent buttons={this.state.buttons}/>
                    {
                        this.state.sections.map(({id, ...otherSectionProps}) => (
                            <Image2Paragraphs key={id} {...otherSectionProps} />
                        ))
                    }
                    <div className="cta-container">
                            <CTAComponent
                                imageUrl={ctaImage}
                                htmlCopy={this.state.ctaInfo.htmlCopy}
                            />
                    </div>
                </main>
                
            </div>
        );
    }
}

export default CancersWeTreatPage;