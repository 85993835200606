import { createSelector } from 'reselect';

const ARTICLE_ID_MAP = {
    'UNLV-Partnership': 1,
    'expertise-and-research': 2,
}

const selectArticles = state => state.article;

export const selectArticlesData = createSelector(
    [selectArticles],
    article => article.articles
)

export const selectArticle = articleUrlParam =>
    createSelector(
        [selectArticlesData],
        articles =>
            articles.find(
                article => article.id === ARTICLE_ID_MAP[articleUrlParam]
            )
    );
