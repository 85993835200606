import React from 'react';
import HomepageGalleryItemComponent from './homepage-gallery-item/homepage-gallery-item.component';
import './homepage-gallery.styles.scss'
import bannerImg from '../../img/pancan.png'

import HOMEPAGE_GALLERY_DATA from "../../pages/homepage/homepage.data";

class HomepageGalleryComponent extends React.Component {
    state = HOMEPAGE_GALLERY_DATA;

    render() {
        return (
            <section className="cards-no-border breathing-room-bottom">

                <div className="row row-cols-1 row-cols-md-4">
                    {
                        this.state.galleryItems.map( ({ id, ...sectionProps }) => (
                            <HomepageGalleryItemComponent key={id} {...sectionProps} />
                        ))
                    }
                </div>
            </section>
        );
    }
}

export default HomepageGalleryComponent;
