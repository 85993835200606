const RADIATION_ONCOLOGY_DATA = {
    sections: [
        {
            imageUrl: "",
            sectionHeader1: "",
            sectionCopy1: "This department is led by our radiation oncologists specializing in radiation therapy to treat cancer. They oversee radiation treatments and work closely with medical oncologists and the entire care team to deliver integrated care. Our team provides patients a deeper understanding of radiation therapy, how it works, and how it can help. Radiation therapy uses high-energy beams to kill cancer-causing cells in the body. It can be used to prevent the further spread of cancer and/or destroy cancer cells depending on the type and stage of cancer. ",
            sectionId1: "radiation-oncology-1",
            sectionHeader2: "",
            sectionCopy2: "Our radiation oncologists are trained to assess patients, develop and administer treatment plans, and help patients manage and minimize any side effect they may encounter along the way.",
            sectionId2: "radiation-oncology-2",
            id: 1
        },
    ],

    ctaInfo : {
        title: 'Schedule a consultation',
        htmlCopy: `<h3 class='dash'>Schedule a consultation</h3>
                    <p>
                    For any cancer related conditions, you can make an appointment by calling
                    <br/><br/>
                    <a href='tel:+17027248787'>1-702-724-8787</a>, <b>TTY 711</b>
                    <br/><br/>
                    Monday–Friday, 8 a.m–5 p.m.
                    </p>
                  `
    }
}; 

export default RADIATION_ONCOLOGY_DATA; 