const BREAST_CARE_DATA = {
    sections: [
        {
            imageUrl: "",
            sectionHeader1: "",
            sectionCopy1: "Women are strong and powerful individuals. They fight for what they believe in and for those they love. We empower women with the tools and support needed to fight cancer. Facing breast cancer can be stressful on the body and the mind. Our breast cancer care team is devoted to helping women manage the various challenges that come with breast cancer. Our highly specialized and integrated team works together with patients to develop a personalized treatment plan. Our approach to medicine and cancer treatment is multidisciplinary, which means we consider how treatment affects energy levels, emotions, and diet. Our compassionate team is here to support each patient every step of the way. We strive to help women and their loved ones navigate throughout their entire care journey from diagnosis to recovery. We also offer patients and their families a wide range of resources including help finding support groups, financial assistance and more. ",
            sectionId1: "breact-care-1",
            sectionHeader2: "",
            htmlCopy1: `<br><p>We put patients first by: </p>
                        <ul>
                            <li>Providing diagnostic mammograms and ultrasounds with same-day results </li>
                            <li>Ensuring any necessary imaging is done during the same appointment </li>
                            <li>Using upright stereotactic mammography so all patients can receive diagnostic testing </li>
                            <li>Sharing a wide range of additional support from the patient resource library to social services and palliative care </li>
                            <li>Enlisting the tumor board of 30 physicians for complex cases, which is the only in-house program in Las Vegas </li>
                        </ul>
                       `,
            sectionId2: "breast-care-2",
            id: 1
        },
    ],

    ctaInfo : {
        title: 'Schedule a consultation',
        htmlCopy: `<h3 class='dash'>Schedule a consultation</h3>
                    <p>
                    For any cancer related conditions, you can make an appointment by calling
                    <br/><br/>
                    <a href='tel:+17027248787'>1-702-724-8787</a>, <b>TTY 711</b>
                    <br/><br/>
                    Monday–Friday, 8 a.m–5 p.m.
                    </p>
                  `
    }
}; 

export default BREAST_CARE_DATA; 