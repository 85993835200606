import React from 'react';
//import {render} from "@testing-library/react";
import LinkGalleryItemComponent from './link-gallery-item.component';
import './link-gallery.styles.scss';
import HOMEPAGE_DATA from "../../pages/homepage/homepage.data";

class LinkGalleryComponent extends React.Component {
    constructor() {
        super();

        this.state = HOMEPAGE_DATA;
    }

    render() {
        return (
            <div className="gallery-container">
                <div className="row">
                    {
                        this.state.linkGallery.map( ({ id, columns,...sectionProps }) => (
                            <LinkGalleryItemComponent key={id} columnQuarter={true} {...sectionProps} />
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default LinkGalleryComponent;
