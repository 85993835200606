const RADIOLOGY_IMAGING_DATA = {
    sections: [
        {
            imageUrl: "",
            sectionHeader1: "",
            htmlCopy1: `<p>Imaging tests are a key part of cancer care and treatment. Radiologists are doctors who specialize in imaging techniques. They’re trained to interpret high-quality images in order to determine a patient's findings and diagnosis. Our radiology team conducts tests using forms of energy such as x-rays and ultrasounds to help doctors see inside the human body. Radiology techniques like these can help locate, diagnose, and show how far a cancer has spread. During cancer treatment, radiology tests can show if the tumor is growing, staying the same, or shrinking which can reveal how treatment is working and if a patient’s treatment plan needs to readjust. <br> We offer an array of imaging services, including: <br> </p>
                        <ul>
                            <li>Conventional x-ray </li>
                            <li>Ultrasound </li>
                            <li>Mammography </li>
                            <li>CT scan </li>
                            <li>PET/CT </li>
                            <li>Bone density scans (DEXA) </li>
                            <li>Angiography </li>
                            <li>Nuclear medicine </li>
                        </ul>
                        <p>Our state-of-the-art angiography suite uses advanced technology for placing and removing chemotherapy ports and PICC lines. These services are done onsite so that treatment can begin the same day. </p>`,
            sectionId1: "radiology-imaging-1",
            id: 1
        },
    ],

    ctaInfo : {
        title: 'Schedule a consultation',
        htmlCopy: `<h3 class='dash'>Schedule a consultation</h3>
                    <p>
                    For any cancer related conditions, you can make an appointment by calling
                    <br/><br/>
                    <a href='tel:+17027248787'>1-702-724-8787</a>, <b>TTY 711</b>
                    <br/><br/>
                    Monday–Friday, 8 a.m–5 p.m.
                    </p>
                  `
    }
}; 

export default RADIOLOGY_IMAGING_DATA; 