const PHARMACY_DATA = {
    sections: [
        {
            imageUrl: "",
            sectionHeader1: "",
            htmlCopy1: `<p>Oncology pharmacists play a crucial role in cancer care. Pharmacists have specialized knowledge about a wide range of medications, how they interact together, and how they work to fight cancer. Certain medications can have intense side effects and our pharmacists work to minimize toxicities and side effects while maximizing treatment results. They also look at a patient’s existing medications to identify any unwanted or problematic drug interactions with their new medication/treatment plan.  </p>
                        <p> Our pharmacists work directly with a patient's cancer care team to coordinate treatment and educate patients about their medications. Oncology pharmacists ensure patients receive the appropriate doses and help patients understand how their medications work and when to take them. Our pharmacists value compassionate care and strive to address all patient concerns. They’re here to help patients and their families understand what to expect during treatment and will follow up with a patient’s oncologist to adjust medications if needed depending on their side effects.  </p>`,
            sectionId1: "pharmacy-1",
            id: 1
        },
    ],

    ctaInfo : {
        title: 'Schedule a consultation',
        htmlCopy: `<h3 class='dash'>Schedule a consultation</h3>
                    <p>
                    For any cancer related conditions, you can make an appointment by calling
                    <br/><br/>
                    <a href='tel:+17027248787'>1-702-724-8787</a>, <b>TTY 711</b>
                    <br/><br/>
                    Monday–Friday, 8 a.m–5 p.m.
                    </p>
                  `
    }
}; 

export default PHARMACY_DATA; 