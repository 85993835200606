import React from 'react';
import { withRouter } from 'react-router-dom';
import "./auxiliary.styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookSquare, faInstagram} from "@fortawesome/free-brands-svg-icons"


const AuxiliaryComponent = () => (
    <div className="container-fluid top-bar">
        <div className="row">
            <div className="d-flex col-12 justify-content-end">
                <ul className="social hide-mobile">
                    <li>
                        <a href="https://instagram.com/optum" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faInstagram} size="lg"/>
                        </a>
                    </li>
                    {/*<li>*/}
                    {/*    <a href="https://www.yelp.com/biz/optumcare-orthopaedics-and-spine-las-vegas" target="_blank">*/}
                    {/*        <FontAwesomeIcon icon={faYelp} size="lg" />*/}
                    {/*    </a>*/}
                    {/*</li>*/}
                    <li>
                        <a href="https://www.facebook.com/myOptum/" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faFacebookSquare} size="lg"/>
                        </a>
                    </li>
                </ul>
                {/*<span className= "auxiliary-text hide-mobile"><a href = "https://www.optumcare.com/state-networks/locations/nevada/ocnv/about-optumcare/contact-us.html" target="_blank">SCHEDULE AN APPOINTMENT </a></span>*/}
                <span className="auxiliary-text"><a href = "tel:+17027248787"> 702-724-8787 </a></span>
                <span className="auxiliary-text-patient-portal"><a href = "https://epicpnwmychart.optum.com/mychart/Authentication/Login" target="_blank" rel="noreferrer">PATIENT PORTAL</a></span>
            </div>
        </div>
    </div>



)

export default withRouter(AuxiliaryComponent);
