const ARTICLES_DATA = {
    articles: [
        {
            title: "UNLV Partnership ",
            sectionHeader1: "",
            url: "UNLV-Partnership",
            author: '',
            company:'',
            body: `
                    <p>The UNLV residency program is the first of its kind in Nevada. Residents learn from our surgeons and train at UMC, a busy Level 1 trauma center. This education provides a solid foundation for their future as leading orthopaedic surgeons who deliver excellent care.</p>

                    <p>It’s well-documented that Southern Nevada needs more doctors to keep up with its growing population.  And, according to the Association of American Medical Colleges (AAMC), 68% percent of doctors who complete all their training in one state end up practicing there. That is happening – now.</p>

                    <p>In June 2021, the Kirk Kerkorian School of Medicine at UNLV / Optum Department of Orthopaedic Surgery Residency Program graduated its very first class of orthopaedic surgeons. </p>

                    <p>Michael Daubs, M.D., is professor and chair for the school’s Department of Orthopaedics. He’s also medical director of Optum Care Orthopaedics and Spine. He designed and launched the residency program. </p>

                    <p>As a graduate of the University of Nevada, Reno and the University of Nevada School of Medicine, he had to leave the state to pursue graduate medical education training in orthopaedic surgery because this type of specialty training was not available.</p>

                    <p>Having experienced this challenge, he made it his goal to return to the city and state he grew up in and start a residency program in orthopaedic surgery. After holding faculty positions in orthopaedic surgery at Washington University in St. Louis, the University of Utah and at UCLA, he returned home in 2014 and took on the position of professor and chief of the division of orthopaedic surgery at the UNLV School of Medicine. His experience as a faculty member at what many consider to be some of the top orthopaedic training programs in the country allowed him to develop the blueprint needed to successfully build this high-quality program in Las Vegas and attract top-notch orthopaedic surgeons as faculty. </p>

                    <p>As a spine surgeon at UCLA, he cared for many Las Vegans who made the trip to Los Angeles for their complex spinal surgery. He witnessed the hardships that traveling out of town for healthcare had on patients and their families. As a Las Vegan whose family roots date back to the 1930s, he understood the issues and frustration. </p>

                    <p>Optum Care Orthopaedic and Spine has played an important part in the residency program. Dr. Daubs relied on the support, participation and feedback of his physician colleagues including Dr. Sukanta Maitra, Dr. Brock Wentz and Dr. Erik Kubiak, who also served as attending physicians for the program. Optum Care Orthopaedic and Spine is at the forefront of clinical medical research – what is called “outcomes-based research” – it is research that examines which procedures work best for patients. </p>

                    <p>With the combination of leading-edge services, the residencies, and the clinical research being pursued, Optum Care Orthopaedic and Spine is raising the bar for medical care in Nevada.</p>
                    
                    <p>In addition to the orthopaedic surgery residency program, Optum’s support for medical education has been substantial. Working with the Kirk Kerkorian School of Medicine at UNLV, Optum has established the “Optum Dr. Tony and Renee Marlon Endowed Chair in the Department of Orthopaedics,” with a pledge of more than $1.2 million over five years.</p>

                    <p>Dr. Tony and Renee Marlon have generously donated a match to those funds, for a total of $2.5 million. Dr. Tony Marlon came to Las Vegas in 1972 as chief of cardiology for what later became UMC. He also founded health care company Sierra Health Services in Las Vegas in 1984, the predecessor to what is now Optum and Southwest Medical in Southern Nevada.</p>

                    <p>This public / private partnership is the first endowment chair for the school and will be instrumental in supporting and promoting the department of orthopaedics as well as orthopaedic education and research in Nevada. Endowed chairs are financially supported by the revenue from an endowment and assist medical schools in the growth of a department by investing in both education and research initiatives. </p>
                    `,
            id: 1
        },
        {

            title: "Expertise and Research",
            sectionHeader1: "",
            url: "expertise-and-research",
            author: '',
            company: '',
            body: `<p>For patients seeking care and other physicians looking to refer their patients for care, they should know that we have a very strong research component. We’re at the forefront of clinical medical research – what is called “outcomes-based research.” It is research that examines which procedures work best for patients. All our physicians are involved. Our collaboration with UNLV also keeps us up to date on the latest in technology and evidence-based medicine.</p>


                <p>We’re continuously learning, conducting research and testing the most innovative technologies to ensure we deliver the most positive impact on our patients lives.</p>


                <p>Las Vegas may not be the first place that comes to mind when thinking about research related to health care. But that is changing thanks to the developing research program at OptumCare Orthopaedics and Spine, whose orthopaedic surgeons and orthopaedic surgery residents, associated with the Kirk Kerkorian School of Medicine at UNLV are currently designing and conducting more than 20 different studies in various stages of completion, covering subjects such as: </p>

                <ul>
                    <li>Have the pedestrian bridges built on the Las Vegas Strip prevented auto-pedestrian accidents and resulting orthopaedic trauma?</li>

                    <li>Cloudy or foul-smelling urine</li>
                    <li>Examining the effects of the COVID lockdown restrictions on the prevalence of orthopaedic injuries because of interpersonal violence.</li>
                    <li>Comparing orthopaedic injuries during the pandemic to orthopaedic injuries occurring before that.</li>
                    <li>Examining the effectiveness of injectables for knee osteoarthritis.</li>
                    <li>Identifying patient characteristics that can be optimized before spinal surgery, to maximize outcomes and minimize complications.</li>
                </ul>


                <p>These studies vary widely in scope and can include patients, their de-identified medical data, computer modeling and biomechanics studies. </p>

                <p>This local research coincides with the Kirk Kerkorian School of Medicine at UNLV’s five-year orthopaedic surgery residency program, where new residents develop their own studies as a required part of their ACGME (Accreditation Council for Graduate Medical Education) training.</p>

                <p>Our expertise has increasingly been recognized in the health care community. Our own Dr. Michael Daubs, medical director of Optum Care Orthopaedics & Spine, was recently named director-elect of the American Board of Orthopaedic Surgery. This organization establishes educational and professional standards for orthopaedic residents and surgeons as well as evaluating the qualifications and competence of orthopaedic surgeons. </p>





                <p>With the combination of the leading-edge services we’re providing, in addition to the residencies and the clinical research we’re pursuing, we’re proud to help raise the bar for medical care in Nevada.</p> `,
            id: 2
        },

        
        {

            title: "Bladder Issues and the Importance of Seeing a Urologist",
            sectionHeader1: "Bladder Cancer",
            url: "bladder-issues-and-the-importance-of-seeing-a-urologist",
            author: 'Dr. Sarah Ryan, M.D.',
            company: 'Urology Specialists of Nevada',
            body: `<p>Hydration, hydration, hydration - remember when people simply just said, "drink plenty of water?"</p>

                <p>Whatever name you give to it, there is good reason for the advice, especially here in the desert
                    Southwest. If you draw a line along the Southern states, this area is often referred to in the
                    medical profession as the ‘Stone Belt’ because of the hot climates where dehydration is common.</p>

                <p>Of course, there are many reasons for kidney stones beyond dehydration. If you've had kidney stones,
                    you know what they are and how they feel. If you haven't been diagnosed before, there are signs to
                    watch out for. But for the (fortunately) uninitiated . . .
                </p>

                <p class="bold">What are kidney stones (besides painful)?</p>

                <p>Kidney stones are small, hard deposits that form inside your kidneys. The stones are made of mineral
                    and acid salts. They can have many causes and can affect any part of your urinary tract from your
                    kidneys to your bladder.</p>

                <p>Passing stones can be very painful, but they usually cause no permanent damage. In some instances,
                    however, stones can become lodged in the urinary tract or cause other complications and require
                    surgery.
                </p>

                <p class="bold">Signs of a problem
                </p>

                <p>You may not experience any symptoms until a stone moves around within your kidney or passes into your
                    ureter (the tube connecting the kidney and bladder). If that happens, you may experience one or more
                    of the following signs and symptoms:</p>
                <ul>
                    <li>A persistent need to urinate</li>
                    <li>Cloudy or foul-smelling urine</li>
                    <li>Fever and chills (if an infection is present)</li>
                    <li>Nausea and vomiting</li>
                    <li>Pain when urinating</li>
                    <li>Severe pain in your sides and back, below the ribs</li>
                    <li>Pain that spreads to your lower abdomen and groin</li>
                    <li>Pain that comes in waves and fluctuates in intensity</li>
                    <li>Pink, red or brown urine</li>
                    <li>Urinating more often than usual</li>
                    <li>Urinating small amounts of urine</li>
                </ul>
                <p>You should seek immediate medical attention if you experience:</p>
                <ul>
                    <li>Blood in your urine</li>
                    <li>Difficulty passing urine</li>
                    <li>Pain so severe that you can't sit still or find a comfortable position</li>
                    <li>Pain accompanied by nausea and vomiting</li>
                    <li>Pain accompanied by fever and chills</li>
                </ul>
                <p class="bold">But why?</p>
                <p>Kidney stones often have no definite, single cause, although there are several factors that can
                    increase your risk:
                </p>
                <p><b>Dehydration.</b> Not drinking enough water each day can increase your risk of kidney stones and
                    people
                    who live in warm climates (like here in Southern Nevada) and those who sweat a lot (e.g., manual
                    labor, especially outdoors) may be at higher risk.
                </p>
                <p><b>Diet.</b> A diet high in protein, sodium and sugar may increase your risk of some types of kidney
                    stones.
                </p>
                <p><b>Digestive diseases and surgery.</b> Chronic diarrhea, inflammatory bowel disease or gastric bypass
                    surgery can cause changes in digestion that increase the levels of stone-forming substances in your
                    urine.
                </p>
                <p><b>Family or personal history.</b> If someone in your family has kidney stones, you're more likely to
                    develop stones. And if you've already had one or more kidney stones, you're at increased risk of
                    developing them again.
                </p>
                <p><b>Obesity.</b> A high body mass index (BMI), large waist size and weight gain have been linked to an
                    increased risk of kidney stones.
                </p>
                <p> Your family doctor can usually treat small kidney stones that don’t block your kidney or cause
                    other problems. But if you have a large kidney stone and experience severe pain or kidney problems,
                    your doctor may refer you to a urologist - a doctor who treats problems in the urinary tract.
                </p>`,
            id: 3
        }
    ]
}

export default ARTICLES_DATA;
