import React from 'react';
import { withRouter } from 'react-router-dom';

const SingleParagraph = ({ title, cssClass, id, imageUrl, bodyContent,  sectionHeader1, sectionHeader2, sectionHeader3, sectionCopy3, sectionCopy1, htmlCopy1, htmlCopy2, sectionCopy2, sectionId1, sectionId2, sectionId3, htmlCopy3, sectionCopy4, sectionCopy5, sectionCopy6, sectionCopy7, sectionCopy8, sectionCopy9, history, linkUrl, match }) => (
    <section className="container-fluid block-container-3">
        <div className="row justify-content-around" id={`${sectionId1}`}>
            <div className="col-md-8 col-sm-12">
                <p className={cssClass}>
                    {sectionCopy1}
                    <div dangerouslySetInnerHTML={{__html: htmlCopy1}} />
                </p>
            </div>
        </div>
    </section>
        )

export default withRouter(SingleParagraph);
