const CLINICAL_TRIAL_DATA = {
  locations: [
    {
      disease_site: "Breast",
      study_number: "AstraZeneca (CAMBRIA-1) D8531C00002",
      study_title:
        "A Study of Camizestrant in ER+/HER2- Early Breast Cancer After at Least 2 Years of Standard Adjuvant Endocrine Therapy (CAMBRIA-1)",
      agent_device: "Camizestrant (oral)",
      link_trial: "https://clinicaltrials.gov/study/NCT05774951?cond=cambria-1&rank=1",
    },
    {
      disease_site: "Bladder",
      study_number: "AstraZeneca D910PC00001 (VOLGA) (Optum)",
      study_title:
        "A Phase III Randomized, Open-Label, Multicenter Study to Determine the Efficacy and Safety of Durvalumab in Combination with Tremelimumab and Enfortumab Vedotin or Durvalumab in Combination with Enfortumab Vedotin for Perioperative Treatment in Patients Ineligible for Cisplatin Undergoing Radical Cystectomy for Muscle Invasive Bladder Cancer (MIBC) VOLGA (Neoadjuvant, Adjuvant; Perioperative)(PFS MO10) Immunotherapy Advarra IRB",
      agent_device: "Enfortumab Vedotin (IV), Durvalumab (IV)",
      link_trial: "https://clinicaltrials.gov/study/NCT04960709?cond=volga&rank=3",
    },
    {
      disease_site: "Prostate",
      study_number: "Merck Sharp & Dohme (MK-5684-004 OMAHA)",
      study_title:
        "A Phase 3, Randomized, Open-label Study of MK-5684 Versus Alternative Abiraterone Acetate or Enzalutamide in Participants with Metastatic Castration-resistant Prostate Cancer (mCRPC) That Progressed On or After Prior Treatment with One Nextgeneration Hormonal Agent (NHA)",
      agent_device: "MK-5684 (oral)",
      link_trial: "https://clinicaltrials.gov/study/NCT06136650?cond=MK-5684-004&rank=1",
    },
    {
      disease_site: "Lung",
      study_number: "Mirati 849-007",
      study_title:
        "MIRATI Theraputics: A Phase 2 Trial of MRTX849 Monotherapy and in Combination with Pembrolizumab in Patients with Advanced Non-Small Cell Lung Cancer with KRAS G12C Mutation",
      agent_device: "MRTX849(oral) and Pembrolizumab (IV) (KEYTRUDA®)",
      link_trial: "https://clinicaltrials.gov/study/NCT04613596?cond=krystal-7&rank=1",
    },
    {
      disease_site: "All Cancer Types",
      study_number: "OptumCare #OCRC-2021-001 (Optum)",
      study_title:
        "Palliative Care for Late-stage Cancer Patients: Comparison of Patient-Reported Quality of Life in Standard versus Home-based Settings - A Supportive Care Study",
      agent_device: "Home-Based Palliative Care",
      link_trial: "#",
    },
    {
      disease_site: "Lung",
      study_number: "Tempus (GEMINI)",
      study_title:
        "A Longitudinal Multi-Omic Biomarker Profiling Study of Patients with Non-Small Cell Lung Cancer (NSCLC)",
      agent_device: "Blood Collection",
      link_trial: "https://clinicaltrials.gov/study/NCT05236114",
    },
    {
      disease_site: "Bladder",
      study_number: "Seagen Inc. (KEYNOTE-D74)",
      study_title:
        "An Open-label, Randomized, Controlled Phase 3 Study of Disitamab Vedotin in Combination with Pembrolizumab Versus Chemotherapy in Subjects with Previously Untreated Locally Advanced or Metastatic Urothelial Carcinoma that Expresses HER2",
      agent_device:
        "Disitamab Vedotin (IV) in Combination with Pembrolizumab (IV)",
      link_trial: "https://clinicaltrials.gov/study/NCT05911295",
    },
    {
      disease_site: "Prostate",
      study_number: "AstraZeneca (EvoPAR)",
      study_title:
        "A Randomized, 2-cohort, Double-blind, Placebo-controlled, Phase III Study of AZD5305 in Combination with Physician’s Choice New Hormonal Agents in Patients with HRRm and non-HRRm Metastatic Castration-Sensitive Prostate Cancer",
      agent_device: "AZD5305 (Oral)",
      link_trial:
        "https://clinicaltrials.gov/study/NCT06120491?cond=EvoPAR&rank=1",
    },
    {
      disease_site: "Lung Cancer Screening",
      study_number: "DELFI Diagnostics (FirstLung)",
      study_title:
        "Implementing Fragmentomics Into Real World Screening Interventions to evaluate clinical utility among individuals with elevated risk for Lung Cancer",
      agent_device: "Lung cancer screening blood test",
      link_trial:
        "https://clinicaltrials.gov/study/NCT06145750?term=DELFI&rank=3",
    },
  ],
};

export default CLINICAL_TRIAL_DATA;
