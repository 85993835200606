import cancerswetreat1 from '../../img/Cancer-Care-Images/treatment_options_inside.png';
import cancerswetreat2 from '../../img/treatment-options/building_pic_2.png';

const TREATMENT_OPTIONS_DATA = {
    sections: [
        {
            imageUrl: cancerswetreat1,
            sectionHeader1: "Radiation therapy",
            sectionCopy1: "Radiation therapy uses energy to treat and kill cancer cells in the body. There are several types of radiation therapy, and it is often used in combination with other treatments such as chemotherapy. The most common form is external beam radiation which is a type of x-ray. These beams of energy do more than diagnose, they can be used to treat cancer. Different forms of cancer require different forms of treatment. Oncologists work closely with radiologists to design radiation-specific treatment regimens based on a patient’s unique needs. A patient's cancer care team will walk them through the treatment process as well as go over any side effects and how to help prevent them. Staying hydrated is important to combat the most common side effect, which is fatigue.",
            sectionId1: "radiation-therapy",
            sectionHeader2: "Chemotherapy",
            sectionCopy2: "Chemotherapy is a drug therapy used to kill cancer-causing cells in the body. Chemotherapy is one of the most common forms of cancer treatment as cancer cells grow and multiply more quickly than healthy cells. Chemotherapy drugs can be used alone or in combination with other therapies. The goal of treatment depends on the type of cancer and how far it has spread. Sometimes the goal is to shrink the tumor or eliminate the cancer entirely. Sometimes the goal is to help slow down the process or limit the spreading of the cancer to other parts of the body. Chemotherapy can be received through injections, intravenous/IV, orally, or even topically. Chemotherapy is often administered for a specific time or in treatment cycles to give the body time to heal and reduce side effects. Our oncologists develop a chemotherapy treatment plan based on a patient’s type of cancer, stage and medical history to set unique health goals. ",
            sectionId2: "chemotherapy",
            cssClass: "border-bottom",
            id: 1
        },
        {
            imageUrl: cancerswetreat2,
            sectionHeader1: "Targeted therapy",
            sectionCopy1: "Targeted therapy is a type of cancer treatment that uses drugs to target specific genes, proteins, and affected tissues where cancer is growing. Cancer researchers use genetics to develop targeted therapy drugs that can locate the mutation and stop the cancer-causing cells and proteins from mutating, growing, or spreading. Our specialists use targeted therapy in combination with other therapies such as chemotherapy to maximize optimal results. Targeted therapy is very personalized, and treatment plans are created based on a patient’s type of cancer, genetics and body. Targeted therapy is a relatively new field of cancer research and can be effective in treating certain types of cancers such as breast cancer, lung cancer, lymphoma and leukemia. While this therapy works incredibly well for some cancers, it is not yet an available option for all cancers. Our oncologists determine the best recommendations as well as what to expect with the side effects that come with targeted therapy. ",
            sectionId1: "targeted-therapy",
            sectionHeader2: "Immunotherapy",
            sectionCopy2: "Immunotherapy, or biologic therapy, is a cancer treatment that uses a person’s own immune system to fight cancer. This class of therapy is one of the newest advancements in cancer treatment. It can boost one’s immune system by enabling it to locate, target and fight cancer cells. There are various methods used to strengthen the immune response. The existing immune system can be simulated to better fight the cancer cells, or parts of the immune system can be given to a patient, such as antibodies or proteins, to fight the cancer with additional help. Immunotherapy can be administered via IV, injections, vaccines, or infusions. Other forms can be taken orally by mouth and can be taken at home in pill form. The duration of treatment varies case by case. It can be administered daily, weekly, monthly or in cycles depending on a patient's treatment plan. Immunotherapy has fundamentally changed how we approach the treatment of cancer. By being able to find the cancer cells and train the immune system to combat those cells, we have been able to improve the treatment of difficult cancers like kidney cancer, lung cancer and melanoma. Immunotherapy is an important part of cancer treatment and will continue developing as a key player in the future of cancer care.",
            sectionId2: "immunotherapy",
            cssClass: "border-bottom",
            id: 2
        },
        {
            imageUrl: "",
            sectionHeader1: "Ablation therapy",
            sectionCopy1: "Ablation therapy is a type of minimally invasive procedure performed to target and destroy cancer-causing cells in different types of tissue throughout the body. Cancer cells and the affected tissue can be stopped or destroyed through radiofrequency ablation which uses heat to kill the cancer cells. Radiologists are trained to perform ablation therapy. There are many ways to deliver treatment which can be through energy beams, probes, or tubes inserted into the skin or arteries. Electrical pulses heat the probes which are placed in the tumor to deliver treatment. Imaging techniques such as magnetic resonance imaging (MRI) and computer-assisted tomography (CAT) help guide the radiologist when locating the site on the body that requires ablation. Cryoablation therapy uses extremely cold temperatures to kill cancer and can help with kidney and prostate cancer. Radiofrequency ablation (RFA) can be performed in an open procedure with full anesthesia, and once the cancer cells die, only a scar is left behind on the body. Common cancers this therapy can treat include lung, metastatic colorectal cancer, breast, thyroid, kidney and liver cancer. ",
            sectionId1: "ablation-therapy",
            sectionHeader2: "Stereotactic body radiation therapy",
            sectionCopy2: "Stereotactic body radiation therapy can help target and treat tumors throughout the body. This type of therapy is very advanced. Breathing affects the motion of tumors and the organs. The innovative technology behind stereotactic body radiation therapy evaluates respiratory motion when administering treatment. A coordinate system is then used to locate the tumor and its movement pattern. Through localized tumor control and treatment, stereotactic body radiation therapy can help minimize side effects and complications in patients. This form of treatment is good for small tumors, primary or early stage 1 cancer, lung cancer, liver cancer, pancreatic cancer, prostate cancer and to treat recurring cancers. Treatment is delivered precisely and accurately over one to five sessions and requires a comprehensive care team. A high potent dose of radiation is delivered from multiple angles to target the tumor. Our highly specialized radiation oncologists safely deliver high doses without damaging the surrounding healthy tissue. This can improve treatment rate and reduce side effects in a way that was previously unavailable with conventional radiation therapy.",
            sectionId2: "stereotactic-body-radiation-therapy",
            cssClass: "border-bottom",
            id: 3
        },
        {
            imageUrl: "",
            sectionHeader1: "Stem cell transplantation ",
            sectionCopy1: "Some cancers develop in the bone marrow or blood while others can spread to it. Stem cell transplants, also called bone marrow transplants, can help treat certain types of cancer such as leukemias, myeloma and lymphoma. Stem cell transplants can help eliminate the cancer cells by providing new healthy stem cells for the body to utilize moving forward. This type of treatment can also help replace bone marrow cells that have been destroyed or compromised due to chemotherapy or radiation therapy while fighting cancer in the body. Once in the body, stem cells can grow into healthy and mature blood cells that work and reproduce normally without producing cancer cells. Donors can facilitate stem cell transplants by donating stem cells. Friends and loved ones as well as expecting mothers who wish to donate umbilical cord blood (which is generally full of healthy stem cells) can be tested to see if they are viable donors. Our oncologists can determine if stem cell transplantation is the best option for cancers of the blood and lymphatic systems.",
            sectionId1: "stem-cell-transplantation",
            // sectionHeader2: " ",
            // sectionCopy2: " ",
            // sectionId2: " ",
            id: 4
        },
        
    ],
    buttons: [
        {
            id: 1,
            buttonCopy: "Radiation therapy",
            buttonLink: "#radiation-therapy"
        },
        {
            id: 2,
            buttonCopy: "Chemotherapy",
            buttonLink: "#chemotherapy"
        },
        {
            id: 3,
            buttonCopy: "Targeted therapy",
            buttonLink: "#targeted-therapy"
        },
        {
            id: 4,
            buttonCopy: "Immunotherapy",
            buttonLink: "#immunotherapy"
        },
        {
            id: 5,
            buttonCopy: "Ablation therapy",
            buttonLink: "#ablation-therapy"
        },
        {
            id: 6,
            buttonCopy: "Stereotactic body radiation therapy",
            buttonLink: "#stereotactic-body-radiation-therapy"
        },
        {
            id: 7,
            buttonCopy: "Stem cell transplantation",
            buttonLink: "#stem-cell-transplantation"
        },

    ],

    ctaInfo : {
        title: 'Schedule a consultation',
        htmlCopy: `<h3 class='dash'>Schedule a consultation</h3>
                    <p>
                    For any cancer related conditions, you can make an appointment by calling
                    <br/><br/>
                    <a href='tel:+17027248787'>1-702-724-8787</a>, <b>TTY 711</b>
                    <br/><br/>
                    Monday–Friday, 8 a.m–5 p.m.
                    </p>
                  `
    }
}

export default TREATMENT_OPTIONS_DATA;