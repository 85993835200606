const CANCER_GENETIC_COUNSELING_DATA = {
    sections: [
        {
            imageUrl: "",
            sectionHeader1: "",
            htmlCopy1: `<p>Cancer genetic counselors are health care providers specializing in genetics and uncovering genetic data to help treat and prevent cancer in patients and their families. Our highly trained counselors can help find hereditary cancer predispositions based on family history and genetic testing. Knowing one's genetics promotes cancer awareness and can lead to prevention as well as early detection. </p>
                        <p>Even after receiving a cancer diagnosis, a genetic counselor can interpret and assess a patient's genetic makeup to see if they’re a good candidate for certain treatments such as targeted therapy. The human body is full of answers, and genetic testing results can improve and expand a patient's treatment options.  </p>
                        <p>As part of our compassionate and comprehensive care efforts, counselors also help patients process the emotional and psychological aspects of discovering their results. Our specialists are here to support patients and help them open the discussion of cancer risk and prevention with their family members and loved ones.  </p>`,
            sectionId1: "hematology-1",
            id: 1
        },
    ],

    ctaInfo : {
        title: 'Schedule a consultation',
        htmlCopy: `<h3 class='dash'>Schedule a consultation</h3>
                    <p>
                    For any cancer related conditions, you can make an appointment by calling
                    <br/><br/>
                    <a href='tel:+17027248787'>1-702-724-8787</a>, <b>TTY 711</b>
                    <br/><br/>
                    Monday–Friday, 8 a.m–5 p.m.
                    </p>
                  `
    }
}; 

export default CANCER_GENETIC_COUNSELING_DATA; 