  
import React from 'react';
import HeaderComponent from "../../../components/header/header.component";
import MENS_HEALTH_DATA from "./mens-health.data";
import Image2Paragraphs from "../../../components/image-with-paragraphs/image-with-paragraphs.component";
import CTAComponent from "../../../components/call-to-action/cta-footer.component";

import heroImage from '../../../img/services/mens-health/mh-header.png';
import ctaImage from '../../../img/Cancer-Care-Images/CTA_Updated.png';
import ButtonsDirectoryComponent from "../../../components/buttons-directory/buttons-directory.component";

import './mens-health.styles.scss'

import { Helmet } from 'react-helmet';

class MensHealthPage extends React.Component {
    state = MENS_HEALTH_DATA;

    render() {
        return (
            <div className="mens-health-page">
                <Helmet>
                    <title>Men's Health | Optum Care Cancer Care</title>
                </Helmet>
                <HeaderComponent imageUrl={heroImage} title="Men's Health" />
                <ButtonsDirectoryComponent buttons={this.state.buttons}/>
                {
                    this.state.sections.map( ({ id, ...otherSectionProps }) => (
                        <Image2Paragraphs key={id} {...otherSectionProps} />
                    ))
                }
                <CTAComponent imageUrl={ctaImage}/>
            </div>
        );
    }
}

export default MensHealthPage;