const HEMATOLOGY_DATA = {
    sections: [
        {
            imageUrl: "",
            sectionHeader1: "",
            htmlCopy1: `<p>Hematology is the study of blood and how it functions. Hematologists and hematopathologists are trained to diagnose and treat blood cancer and blood disorders. These conditions can affect the blood, bone marrow and circulatory system. Our specialists can treat many diseases such as: </p><br>
                        <ul>
                            <li>Hodgkin lymphoma </li>
                            <li>Non-Hodgkin lymphoma </li>
                            <li>Acute myeloid leukemia </li>
                            <li>Chronic myeloid leukemia </li>
                            <li>Hemophilia </li>
                            <li>Iron deficiency anemia </li>
                            <li>Sickle cell disease </li>
                            <li>von Willebrand disease </li>
                            <li>Clotting disorders </li>
                        </ul><br>
                        <p>Our hematology care team works directly with patients and the rest of a patient’s care team to understand, diagnose, and treat cancer affecting the blood. The team is led by the hematologist who works with the patient to determine the best course of treatment for specific blood cancers. Treatments can range from radiation therapy or stem cell transplantation. Our team helps walk the patient and family through the personalized care plan with clear communication and treatment goals. </p>`,
            sectionId1: "hematology-1",
            sectionHeader2: "",
            sectionCopy2: "",
            sectionId2: "hematology-2",
            id: 1
        },
    ],

    ctaInfo : {
        title: 'Schedule a consultation',
        htmlCopy: `<h3 class='dash'>Schedule a consultation</h3>
                    <p>
                    For any cancer related conditions, you can make an appointment by calling
                    <br/><br/>
                    <a href='tel:+17027248787'>1-702-724-8787</a>, <b>TTY 711</b>
                    <br/><br/>
                    Monday–Friday, 8 a.m–5 p.m.
                    </p>
                  `
    }
}; 

export default HEMATOLOGY_DATA; 