
import React from 'react';
import { withRouter } from 'react-router-dom';
import './interior-full-width.scss'
import bannerImg from "../../img/pancan.png";

const InteriorFullWidthComponent = ({ title, id, imageUrl, bodyCopy, htmlCopy, imageLeft, history, linkUrl, match, imageHeight, imageWidth }) => (
    <div>
        {/*
         <section className="cards-no-border breathing-room-bottom interior-row">
        <a href="https://secure.pancan.org/site/TR/PurpleStride/PurpleStride?team_id=48246&pg=team&fr_id=2802" target="_blank"><img src={`${bannerImg}`} className="img-fluid banner" width="100%" height="auto" alt=""/></a>
    </section>
        */}
    <section className="container-fluid cards-no-border breathing-room-bottom" >
                <div className="row">
                    <div className="col-8 left-column-content interior-row-container">
                        {title &&
                        <h1 className={`${title ? "dash" : ""}`}>{title} {imageLeft}</h1>
                        }
                        {bodyCopy &&
                        <p className='interior-row-text'>{bodyCopy}</p>
                        }
                        <div dangerouslySetInnerHTML={{__html: htmlCopy}} />
                    </div>
                </div>
    </section>

    </div>
)

export default withRouter(InteriorFullWidthComponent);