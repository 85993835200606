import React from 'react';
//import {render} from "@testing-library/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight} from "@fortawesome/free-solid-svg-icons";

const ButtonComponent = ( {id, buttonCopy, buttonLink, className, icon } ) => {
    return (
        <>
            <a key={id} className={`${className ? className : "btn" } no-underline btn"`} href={`${buttonLink}`} target="_self">
                {buttonCopy}
                <FontAwesomeIcon className="ml-1" icon={icon} />
            </a>
        </>
    )
}

export default ButtonComponent;
