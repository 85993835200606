import React from 'react';
import HeaderComponent from "../../../components/header/header.component";
import CTAComponent from "../../../components/call-to-action/cta-footer.component";
import BREAST_CARE_DATA from "./breast-care.data";

import ctaImage from '../../../img/Cancer-Care-Images/CTA_Updated.png'
import heroImage from '../../../img/Cancer-Care-Images/breast-care-hero.png';
import {Helmet} from "react-helmet";
import {hideMask} from "../../../components/navigation/navigation.component";
import SingleParagraph from "../../../components/single-paragraph/single-paragraph";
import seal from "../../../img/Seal-Excellence-Center.jpg"

class BreastCarePage extends React.Component {
    pageTitle = "Breast Care";
    state = BREAST_CARE_DATA;
    render() {
        return (
            <div>
                <Helmet>
                    <title>{this.pageTitle} | Optum Care Cancer Care</title>
                </Helmet>
                <main className='page-body' onClick={hideMask}>
                
                    <HeaderComponent imageUrl={heroImage} title={this.pageTitle} />

                    
                    {
                        this.state.sections.map(({id, ...otherSectionProps}) => (
                            <SingleParagraph key={id} {...otherSectionProps} />
                        ))
                    }

                    <section className="container-fluid block-container-3">
                    <div className='row justify-content-center'>
                        <div className="col-md-6 col-sm-12 my-auto">
                            <p className="text-left">
                                Optum Care Breast Care has been recognized as a Certified Quality Breast Center of Excellence, which is the highest recognition by the National Quality Measure for Breast Centers.
                            </p>
                        </div>

                        <div className="col-md-2 col-sm-12 my-auto">
                            <p className="text-left">
                                <img width="120px" src={seal}/>
                            </p>
                        </div>
                    </div>
                    </section>

                    <div className="cta-container">
                            <CTAComponent
                                imageUrl={ctaImage}
                                htmlCopy={this.state.ctaInfo.htmlCopy}
                            />
                    </div>
                </main>
            </div>
            
        );
    }   
}

export default BreastCarePage;