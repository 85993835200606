
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight} from "@fortawesome/free-solid-svg-icons";
//import {render} from "@testing-library/react";

const HomepageGalleryItemComponent = ({imageUrl, linkUrl, bodyCopy, bodyCopy2, title, id, buttonText, columnQuarter}) => (
    <div className={`${columnQuarter ? " col-md-3"  : " col-md-4"} mb-4`} key={id}>
                <div className="home-card card h-100">
                    <div className="card-body">
                        <img alt="" className="card-img-top homepage-gallery-item-image" src={`${imageUrl}`}/>
                            <h4 className="card-title">{title}</h4>
                            <p className="card-text">
                                {bodyCopy}
                                <br/>
                                {bodyCopy2}
                            </p>
                            {buttonText &&
                                <a href={`${linkUrl}`} className="button-link">
                                    {buttonText}

                                    <FontAwesomeIcon className="ml-2 pt-1" icon={faChevronRight} />

                                </a>
                            }
                    </div>
                </div>
            </div>
)

export default HomepageGalleryItemComponent;
