import './App.css';
import './main'
import 'bootstrap/dist/css/bootstrap.min.css';
import './main.styles.scss'
import React from 'react';
import {Switch, Route} from 'react-router-dom';



import AuxiliaryComponent from "./components/auxiliary/auxiliary-bar.component"
import NavigationComponent from "./components/navigation/navigation.component";
import HomePage from './pages/homepage/homepage.component';
import ProvidersPage from "./pages/providers-gallery/providers-gallery.component";
import LocationsPage from "./pages/locations/locations.component";
import MensHealthPage from "./pages/services/mens-health/mens-health.component";
import FooterComponent from "./components/footer/footer.component"
import WomensHealthPage from "./pages/services/womens-health.component.jsx/womens-health.component";
import NewPatientInformationPage
    from "./pages/patient-resources/new-patient-information/new-patient-information.component";
import ProcedureConsentFormsPage
    from "./pages/patient-resources/procedure-consent-forms/procedure-consent-forms.component";
import HowToPayYourBillPage from "./pages/patient-resources/how-to-pay-your-bill/how-to-pay-your-bill.component";
import AboutUsPage from "./pages/about-us/about-us.component";
import CancerCarePage from "./pages/services/cancer-care/cancer-care.component";
import ProviderTemplatePage from "./pages/providers-single-page/providers-template.component";
import ArticlePage from "./articles/articles.component";
import CancersWeTreatPage from './pages/cancers-we-treat/cancers-we-treat.component';
import TreatmentOptionsPage from './pages/treatment-options/treatment-options.component';
import ClinicalTrials from "./pages/clinical-trials/clinical-trials.component";
import MedicalOncologyPage from "./pages/services/medical-oncology/medical-oncology.component";
import RadiationOncologyPage from "./pages/services/radiation-oncology/radiation-oncology.component";
import BreastCarePage from "./pages/services/breast-care/breast-care.component";
import HematologyPage from "./pages/services/hematology/hematology.component";
import CancerGeneticCounselingPage from "./pages/services/cancer-genetic-counseling/cancer-genetic-counseling.component";
import RadiologyImagingPage from "./pages/services/radiology-imaging/radiology-imaging.component";
import PalliativeCarePage from "./pages/services/palliative-care/palliative-care.component";
import PharmacyPage from "./pages/services/pharmacy/pharmacy.component";
import NutritionPage from "./pages/services/nutrition/nutrition.component";
import PatientResourcesGeneralPage from "./pages/patient-resources/patient-resources-general.component";




class App extends React.Component {
    render(){
        return (
            <main>
                <AuxiliaryComponent/>
                <NavigationComponent/>
                    <Switch>
                        <Route exact path='/' component={HomePage} />
                        <Route exact path='/cancers-we-treat' component={CancersWeTreatPage} />
                        <Route exact path='/treatment-options' component={TreatmentOptionsPage} />
                        <Route exact path='/medical-oncology' component={MedicalOncologyPage} />
                        <Route exact path='/radiation-oncology' component={RadiationOncologyPage} />
                        <Route exact path='/breast-care' component={BreastCarePage} />
                        <Route exact path='/hematology' component={HematologyPage} />
                        <Route exact path='/cancer-genetic-counseling' component={CancerGeneticCounselingPage} />
                        <Route exact path='/radiology-imaging' component={RadiologyImagingPage} />
                        <Route exact path='/palliative-care' component={PalliativeCarePage} />
                        <Route exact path='/pharmacy' component={PharmacyPage} />
                        <Route exact path='/nutrition' component={NutritionPage} />
                        <Route exact path='/patient-resources-general' component={PatientResourcesGeneralPage} />
                        <Route exact
                               path='/about-us'
                               component={AboutUsPage}
                        />
                        <Route exact path='/providers'
                               component={ProvidersPage}
                        />
                        <Route exact
                               path='/locations'
                               component={LocationsPage}
                        />
                        <Route exact
                               path='/clinical-trials'
                               component={ClinicalTrials}
                        />
                        <Route exact
                               path='/services/mens-health'
                               component={MensHealthPage}
                        />
                        <Route exact
                               path='/services/womens-health' component={WomensHealthPage} />
                        <Route exact
                               path='/services/cancer-care' component={CancerCarePage} />

                        <Route exact
                               path='/patient-resources/new-patient-information' component={NewPatientInformationPage} />
                        <Route exact
                               path='/patient-resources/' component={HowToPayYourBillPage} />
                        <Route exact
                               path='/patient-resources/procedure-consent-forms' component={ProcedureConsentFormsPage} />
                        <Route exact
                               path='/patient-resources/how-to-pay-your-bill' component={HowToPayYourBillPage} />
                        <Route path='/providers/:providerId' component={ProviderTemplatePage} />
                        <Route path='/articles/:articleId' component={ArticlePage} />
                        <Route component={HomePage} />
                    </Switch>
                <FooterComponent/>
            </main>
        );
    }
}

export default App;
