const PALLIATIVE_CARE_DATA = {
    sections: [
        {
            imageUrl: "",
            sectionHeader1: "",
            htmlCopy1: `<p>Palliative care is specialized medical care that strives to provide patients relief from pain and discomfort while receiving cancer treatment. We provide supportive care and symptom management for patients. The Optum Care palliative care department has partnered with Optum Care Cancer Center to help care for patients throughout their treatment. We assign each patient a team of providers, nurses, and social workers specially trained in the issues that arise in life-changing illnesses. <br>The team will put together a care plan that will help our patient:  </p>
                        <ul>
                            <li>Know as much about their illness as they can and understand all options</li>
                            <li>Determine health care goals</li>
                            <li>Strive toward those goals through thoughtful, personalized, and coordinated care</li>
                        </ul>
                        <p>Our compassionate and comprehensive palliative care team works directly with patients and their families each week. This is to make sure the care plan is fulfilling the patient’s and family's needs. </p><br>`,
            sectionId1: "palliative-care-1",
            id: 1
        },
    ],

    ctaInfo : {
        title: 'Schedule a consultation',
        htmlCopy: `<h3 class='dash'>Schedule a consultation</h3>
                    <p>
                    For any cancer related conditions, you can make an appointment by calling
                    <br/><br/>
                    <a href='tel:+17027248787'>1-702-724-8787</a>, <b>TTY 711</b>
                    <br/><br/>
                    Monday–Friday, 8 a.m–5 p.m.
                    </p>
                  `
    }
}; 

export default PALLIATIVE_CARE_DATA; 