import React from 'react';
import { withRouter } from 'react-router-dom';
import "./navigation.styles.scss";

import {Navbar, Nav, NavDropdown} from "react-bootstrap";

import NAVIGATION_DATA from './navigation.data';

export function handleClick(){
    if(document.getElementsByClassName('body-overlay').length === 0) {
        document.getElementsByClassName('page-body')[0].classList.add('body-overlay');
        document.getElementsByClassName('footer-component')[0].classList.add('footer-overlay');
        if (document.getElementsByClassName('dropdown-menu')[0]) {
            document.getElementsByClassName('dropdown-menu')[0].style.display = 'block';
        }
    } else {
        document.getElementsByClassName('page-body')[0].classList.remove('body-overlay');
        document.getElementsByClassName('footer-component')[0].classList.remove('footer-overlay');
        document.getElementsByClassName('dropdown-menu')[0].style.display = 'none';
    }
}

export function hideMask(){
    document.getElementsByClassName('page-body')[0].classList.remove('body-overlay');
    if (document.getElementsByClassName('dropdown-menu')[0]) {
        document.getElementsByClassName('dropdown-menu')[0].style.display = 'none';
        document.getElementsByClassName('footer-component')[0].classList.remove('footer-overlay');
    }
}

class NavigationComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOverlayVisible:false
        };
    }


    navInfo = NAVIGATION_DATA;

    render() {
        return(
            <nav>
                <div className="top-nav mt-1">
                    <div className="row d-flex justify-content-between">
                        <Navbar.Brand href="/"><img id="main-logo" src={this.navInfo.navLogo} alt="Website Logo"/></Navbar.Brand>
                        {this.navInfo.logoDivider && <img src={this.navInfo.logoDivider}/>}
                        {this.navInfo.navLogo2 && <Navbar.Brand id="alt-logo" href="/"><img src={this.navInfo.navLogo2} alt="Partner Logo"/></Navbar.Brand> }
                    </div>
                </div>
                <div className="container-large">
                    <div className="row d-flex justify-content-between main-nav vertical-center">
                        <div className="col-12 p-0 optum-menu-nav">
                            <Navbar collapseOnSelect expand="lg" className="navbar optum-nav navbar-light mobile-nav pl-0">
                                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                <Navbar.Collapse id="responsive-navbar-nav">
                                    <Nav className="">
                                        {
                                            this.navInfo.linkItems.map( ({ id, ...otherSectionProps }) => (
                                                <>
                                                    { otherSectionProps.children.length > 0 &&
                                                    <NavDropdown className="" title={otherSectionProps.name}
                                                                 id="collapsible-nav-dropdown"
                                                                 onClick={handleClick.bind(this)}
                                                    >
                                                        {otherSectionProps.children.map(({name, link}) => (
                                                            <NavDropdown.Item href={link}>{name}</NavDropdown.Item>
                                                        ))
                                                        }
                                                    </NavDropdown>
                                                    }

                                                    { otherSectionProps.children.length === 0 &&
                                                    <div className="nav-item">
                                                        <Nav.Link key={id} href={otherSectionProps.link}>{otherSectionProps.name}</Nav.Link>
                                                    </div>
                                                    }
                                                </>
                                            ))
                                        }
                                    </Nav>
                                    {this.navInfo.rightHtml &&
                                    <div dangerouslySetInnerHTML={{__html: this.navInfo.rightHtml}} />
                                    }
                                </Navbar.Collapse>
                            </Navbar>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }

}

export default withRouter(NavigationComponent);
