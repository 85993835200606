import React from 'react';
import './link-gallery-item.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight} from "@fortawesome/free-solid-svg-icons";

const LinkGalleryItemComponent = ({imageUrl, linkUrl, bodyCopy, title, id, buttonText, columnQuarter,isBlank}) => (
    <div className={`${columnQuarter ? " col-lg-3"  : " col-lg-4"} col-md-4 mb-4 col-12`} key={id}>
        <div className="card document-gallery-item-card">
            <div className="link-gallery-card-body centered-div-column">
                <h4 className="card-title dash align--center">{title}</h4>
                {bodyCopy &&
                    <p className="card-text">
                        {bodyCopy}
                    </p>
                }
                <a href={`${linkUrl}`} target={`${isBlank ? "_blank" : "" }`}
                   className={`${buttonText ? "phone-button " : "" } link-gallery-button-link button-link`}>
                    { buttonText }
                    <FontAwesomeIcon className="ml-1" icon={faChevronRight} />
                </a>
            </div>
        </div>
    </div>
)

export default LinkGalleryItemComponent;
