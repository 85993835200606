import firebase from 'firebase'
var firebaseConfig = {
    apiKey: "AIzaSyCLir_YhlVTUIRggQQa3SlI-O_sUL4_9lI",
    authDomain: "optum-cancer-care.firebaseapp.com",
    projectId: "optum-cancer-care",
    storageBucket: "optum-cancer-care.appspot.com",
    messagingSenderId: "561440925339",
    appId: "1:561440925339:web:46f0e6f62b55f1322c083e"
};
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}else {
    firebase.app(); // if already initialized, use that one
}
export default firebase;
