import React from 'react';
import HeaderComponent from "../../components/header/header.component";
import Image2Paragraphs from "../../components/image-with-paragraphs/image-with-paragraphs.component";
import CTAComponent from "../../components/call-to-action/cta-footer.component";
import PATIENT_RESOURCES_GENERAL_DATA from "./patient-resources-general.data";

import ctaImage from '../../img/Cancer-Care-Images/CTA_Updated.png'
import heroImage from '../../img/Cancer-Care-Images/patient-resources-hero.png';
import Interior7525Component from "../../components/interior-75-25/interior-75-25.component";
import {Helmet} from "react-helmet";
import {hideMask} from "../../components/navigation/navigation.component";
import TextSectionButtonComponent from "../../components/text-section-button/text-section-button.component";

class PatientResourcesGeneralPage extends React.Component {
    pageTitle = "Support for all your care needs";
    state = PATIENT_RESOURCES_GENERAL_DATA;
    render() {
        return (
            <div>
                <Helmet>
                    <meta name="description" content="Patient Resources" />
                    <title>{this.pageTitle} | Optum Care Cancer Care</title>
                </Helmet>
                <main className='page-body' onClick={hideMask}>
                    <HeaderComponent imageUrl={heroImage} title={this.pageTitle} />
                    <div className='row justify-content-around'>
                        <div className="col-md-8 col-sm-12">
                            <p className="text-centered">
                                We offer a wide range of resources and services to help patients feel supported and cared for throughout their entire cancer care journey. Cancer is not a battle anyone needs to fight alone. We’re here for you and offer everything from emotional support advocates as well as financial support services and more.
                            </p>
                        </div>
                    </div>
                    <div className="image2paragraphs-container">
                        {
                            this.state.textSection1.map( ({ id, ...otherSectionProps }) => (
                                <TextSectionButtonComponent key={id} {...otherSectionProps} />
                            ))
                        }
                        {
                            this.state.sections.map(({id, ...otherSectionProps}) => (
                                <Image2Paragraphs key={id} {...otherSectionProps} />
                            ))
                        }
                    </div>
                    <div className="row justify-content-around no-padding">
                        <div className="col-md-12 col-sm-12">
                    {
                        this.state.textSection2.map( ({ id, ...otherSectionProps }) => (
                            <TextSectionButtonComponent key={id} {...otherSectionProps} />
                        ))
                    }
                        </div>
                    </div>
                    <div className="row justify-content-around no-padding">
                    <div className="col-md-10 col-sm-12">
                    {
                        this.state.interiorSection.map( ({ id, ...otherSectionProps }) => (
                            <Interior7525Component key={id} {...otherSectionProps} />
                        ))
                    }
                    </div>
                    </div>
                    <div className="row justify-content-around no-padding">
                        <div className="col-md-12 col-sm-12">

                            {
                                this.state.section2.map(({id, ...otherSectionProps}) => (
                                    <Image2Paragraphs key={id} {...otherSectionProps} />
                                ))
                            }
                        </div>
                    </div>

                    <div className="row justify-content-around no-padding">
                        <div className="col-md-12 col-sm-12">

                        {
                            this.state.section3.map(({id, ...otherSectionProps}) => (
                                <Image2Paragraphs key={id} {...otherSectionProps} />
                            ))
                        }
                    </div>
                    </div>
            
                    <div className="cta-container">
                            <CTAComponent
                                imageUrl={ctaImage}
                                htmlCopy={this.state.ctaInfo.htmlCopy}
                            />
                    </div>
                </main>
                
            </div>
        );
    }
}

export default PatientResourcesGeneralPage;