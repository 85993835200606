import React from 'react';
import './divider-arrow-down.styles.scss'

const DividerArrowDownComponent = ({title, width,divId}) => (
    <div className='row divider-arrow-down-container justify-content-around mt-5'
        id={divId}
    >
        <div className={`${width ? `col-md-${width}` : 'col-md-8' } col-sm-12`}>
            <p className="text-centered">
                {title}
            </p>
            <div className="divider-broken arrow-down"></div>
        </div>
    </div>
)

export default DividerArrowDownComponent;
