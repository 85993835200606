import React from 'react';
import HeaderComponent from "../../components/header/header.component";
import LocationsRowComponent from "../../components/locations-row/locations-row.component";
import LOCATIONS_DATA from "./locations.data";
import heroImage from '../../img/locations/locations.png';
import {Helmet} from "react-helmet";
import {hideMask} from "../../components/navigation/navigation.component";

class LocationsPage extends React.Component {

    state = LOCATIONS_DATA;
    pageTitle = "Locations";
    render() {
        return (
            <>
                <Helmet>
                    <title>{this.pageTitle} | Optum Care Cancer Care</title>
                </Helmet>
                <main className='page-body' onClick={hideMask}>
                    <HeaderComponent imageUrl={heroImage} title={this.pageTitle}/>
                    <div className='row justify-content-around'>
                        <div className="col-md-8 col-sm-12">
                            <hr/>
                        </div>
                    </div>
                        {
                            this.state.locations.map( ({ id, ...otherSectionProps }) => (
                                <LocationsRowComponent key={id} {...otherSectionProps} />
                            ))
                        }
                </main>
            </>
        );
    }
}

export default LocationsPage;
