import React from 'react';
import { withRouter } from 'react-router-dom';
import ButtonComponent from "../buttons/button.component";

import './text-section-button.styles.scss';

const TextSectionButtonComponent = ({ title, title2, bodyTextLine1, bodyTextLine2, bodyTextLine3,
                                        customSectionClass, htmlCopy, buttonText, buttonLink, buttonClass, customDivClass,
                                        customTitle1Class, customTitle2Class, customBodyTextLine1Class }) => (
    <section className={`${customSectionClass ? `${customSectionClass}` : ''} text-section-component`} >
        <div className="row">
            <div className={`${customDivClass ? `${customDivClass}` : ""} interior-row-container`}>
                {title &&
                <h1 class={`${customTitle1Class ? `${customTitle1Class}` : ""}`}> {title} </h1>
                }
                {title2 && <h2 class={`${customTitle2Class ? `${customTitle2Class}` : ""}`}>{title2}</h2> }

                {bodyTextLine1 &&
                <p class={customBodyTextLine1Class}> {bodyTextLine1}</p>
                }
                {bodyTextLine2 &&
                <p>{bodyTextLine2}</p>
                }
                {bodyTextLine3 &&
                <p>{bodyTextLine3}</p>
                }
                <div dangerouslySetInnerHTML={{__html: htmlCopy}} />
                {buttonText&&
                <ButtonComponent
                    buttonCopy={buttonText}
                    buttonLink={buttonLink}
                    className={buttonClass}
                />
                }

            </div>
        </div>
    </section>
)

export default withRouter(TextSectionButtonComponent);
