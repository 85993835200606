import navLogo from "../../img/standard/logo.svg";

const NAVIGATION_DATA = {
    navLogo: navLogo,
    homeUrl: '/',
    linkItems: [
        {
            id: 1,
            name: 'Services',
            link: '/providers',
            children: [
                {
                    name: 'Medical Oncology',
                    link: '/medical-oncology'
                },
                {
                    name: 'Radiation Oncology',
                    link: '/radiation-oncology'
                },
                {
                    name: 'Breast Care',
                    link: '/breast-care'
                },
                {
                    name: 'Hematology',
                    link: '/hematology'
                },
                // {
                //     name: 'Cancer Genetic Conseling',
                //     link: '/cancer-genetic-counseling'
                // },
                {
                    name: 'Radiology/Imaging',
                    link: '/radiology-imaging'
                },
                {
                    name: 'Palliative Care',
                    link: '/palliative-care'
                },
                {
                    name: 'Pharmacy',
                    link: '/pharmacy'
                },
                {
                    name: 'Nutrition',
                    link: '/nutrition'
                },

            ]
        },
        {
            id: 2,
            name: 'Cancers we treat',
            link: '/cancers-we-treat',
            children: []
        },
       {
           id: 3,
           name: 'Treatment',
           link: '/treatment-options',
           children: [
          ]
      },
        {
            id: 4,
            name: 'Providers',
            link: '/providers',
            children: []
        },
        {
            id: 5,
            name: 'Locations',
            link: '/locations',
            children: []
        },
        {
            id: 6,
            name: 'Clinical trials',
            link: 'clinical-trials',
            children: [
            ]
        },
        {
            id: 7,
            name: 'Patient resources',
            link: '/patient-resources-general',
            children: []
        },

    ],
    rightHtml: ``
}

export default NAVIGATION_DATA;
